.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 33, 63, 0.7);
  backdrop-filter: blur(7.5px);
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  padding-top: 20%;
}

.container {
  border-radius: 36px;
  border: 3px solid #2ee1d6;
  background: #fff;
  padding: 52px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.errorContainer {
  border-radius: 36px;
  border: 3px solid #d43350;
  background: #fff;
  padding: 52px 16px 32px 16px;
  display: flex;
  flex-direction: column;

  position: relative;
}
.title {
  color: var(--Slate-Grey, #34303d);
  text-align: center;
  font-family: IQOS Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.list {
  list-style-type: decimal;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
}

.secondList {
  list-style-type: decimal;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0;
  padding-left: 22px;
}

.item {
  display: flex;
  align-items: center;
  gap: 12px;
}
.image {
  background: #2ee1d6;
  border: 1px solid #78ffe8;
  box-shadow: 5.805px 3.317px 3.317px -2.488px rgba(96, 234, 210, 0.72) inset,
    -1.659px -0.829px 4.976px 0px #0ebbbf inset;
  min-width: 68px;
  min-height: 68px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  background: #2ee1d6;
  border: 1px solid #78ffe8;
  border-radius: 50%;
  min-width: 74px;
  width: 74px;
  min-height: 74px;
  height: 74px;
  box-shadow: 6.317px 3.61px 3.61px -2.707px rgba(96, 234, 210, 0.72) inset,
    -1.805px -0.902px 5.415px 0px rgba(25, 143, 122, 0.46) inset;
  filter: drop-shadow(3.61px 6.317px 9.927px rgba(3, 204, 169, 0.76));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -38px;

  left: calc(50% - 38px);
}

.errorIcon {
  background: #d43350;
  border: 1px solid #fd8ea2;
  border-radius: 50%;
  min-width: 74px;
  width: 74px;
  min-height: 74px;
  height: 74px;
  box-shadow: 6.317px 3.61px 3.61px -2.707px #f23356 inset,
    -1.805px -0.902px 5.415px 0px rgba(163, 0, 29, 0.46) inset;
  filter: drop-shadow(3.61px 6.317px 9.927px rgba(216, 26, 60, 0.76));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -38px;
  left: calc(50% - 38px);
}

.bubbles {
  position: absolute;
}

.button {
  border-radius: 50px;
  border: 1px solid var(--White, #fff);
  background: #e9ffff;
  padding: 10px;
  width: calc(100% - 40px);
  /* position: absolute;
  bottom: 10px; */
}

.buttonText {
  border-radius: 100px;
  border: 1px solid rgba(152, 249, 255, 0);
  background: #26d7ee;
  box-shadow: 4px 7px 11px -6px #01366a, 6px 8px 8px -3px #73f2ff inset,
    -2px -4px 7px 0px rgba(0, 175, 186, 0.69) inset,
    -7px -8px 17px -5px rgba(102, 237, 255, 0.31);
  width: 100%;
  padding: 24px 0px;
  color: var(--Slate-Grey, #34303d);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

@media screen and (max-height: 660px) {
  .errorContainer {
    padding-bottom: 16px;
  }
  .image {
    width: 30px;
    height: 30px;
  }
  .text {
    font-size: 12px;
  }

  .title {
    font-size: 26px;
  }
  .container {
    gap: 0;
  }

  .overlay {
    padding-top: 15%;
  }
  .buttonText {
    padding: 16px 0;
  }
}
