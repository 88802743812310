.tools {
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--White, #fff);
  text-align: center;
  font-family: IQOS Sans;
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 120.93% */
  margin-bottom: 24px;
}
.name {
  color: var(--Slate-Grey, #34303d);

  /* New Mobile_H3 */
  font-family: IQOS Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  position: absolute;
  top: 23px;
  left: 21px;
}

.container {
  position: relative;
  width: 100%;
  height: 157px;
  border-radius: 16px;
  background: var(--Soft-Turquoise, #e9ffff);
  margin-bottom: 30px;
  border: none;
}

.button {
  position: absolute;
  bottom: -17px;
  left: 21px;
  width: 74px;
  height: 74px;
  border-radius: 100px;
  border: 1px solid rgba(152, 249, 255, 0);
  background: #26d7ee;
  box-shadow: 4px 7px 11px -6px #01366a, 6px 8px 8px -3px #73f2ff inset,
    -2px -4px 7px 0px rgba(0, 175, 186, 0.69) inset,
    -7px -8px 17px -5px rgba(102, 237, 255, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
}

.solid1 {
  position: absolute;
  bottom: 0;
  right: 63px;
}

.solid {
  position: absolute;
  bottom: 10px;
  right: 19px;
}

.duo {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 1;
}

.duo1 {
  position: absolute;
  bottom: 0;
  right: 10px;
}

@media screen and (max-height: 600px) {
  .solid1 {
    max-height: 100%;
  }

  .solid {
    max-height: 100%;
  }

  .duo {
    max-height: 100%;
  }

  .duo1 {
    max-height: 100%;
  }
}

@media screen and (max-height: 550px) {
  .button {
    width: 50px;
    height: 50px;
  }
}
