.guide {
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  padding: 0 40px;
  color: var(--White, #fff);
  text-align: center;
  font-family: IQOS Sans;
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 120.93% */
  margin-bottom: 38px;
}

.text {
  color: var(--Slate-Grey, #34303d);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  padding: 20px;
  border-radius: 16px;
  background: var(--Soft-Turquoise, #e9ffff);
}

.bottomText {
  color: var(--White, #fff);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  margin-bottom: 24px;
}

.button {
  border-radius: 50px;
  border: 1px solid var(--White, #fff);
  background: #e9ffff;
  padding: 10px;
  width: 100%;
}

.buttonText {
  border-radius: 100px;
  border: 1px solid rgba(152, 249, 255, 0);
  background: #26d7ee;
  box-shadow: 4px 7px 11px -6px #01366a, 6px 8px 8px -3px #73f2ff inset,
    -2px -4px 7px 0px rgba(0, 175, 186, 0.69) inset,
    -7px -8px 17px -5px rgba(102, 237, 255, 0.31);
  width: 100%;
  padding: 24px 0px;
  color: var(--Slate-Grey, #34303d);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.imgContainer {
  display: flex;
  justify-content: space-around;
}
.img {
  max-height: 250px;
}

@media screen and (max-height: 800px) {
  .title {
    margin-bottom: 18px;
    font-size: 34px;
  }
  .text {
    padding: 10px;
  }

  .buttonText {
    padding: 16px 0;
  }
  .img {
    max-height: 170px;
  }
}

@media screen and (max-height: 600px) {
  .img {
    height: 130px;
  }
}

@media screen and (max-height: 550px) {
  .title {
    font-size: 24px;
  }

  .text {
    font-size: 16px;
    line-height: 100%;
  }
  .img {
    height: 90px;
  }
}
