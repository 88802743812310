.first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

.navbar {
  display: flex;
  gap: 1px;
  align-items: center;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  padding: 2px;
  width: 100%;
  margin-bottom: 32px;
}

.nav {
  border-radius: 20px;
  background: #fff;
  width: 100%;
  height: 6px;
}

.title {
  color: var(--White, #fff);
  font-family: IQOS Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 112.5% */
  margin-bottom: 32px;
}

.answerContainer {
  border-radius: 70px;
  border: 3px solid rgba(255, 255, 255, 0.57);
  background: var(--Soft-Turquoise, #e9ffff);
  box-shadow: 0px 4px 5px 0px rgba(255, 255, 255, 0.59) inset;
  width: 100%;
  padding: 6px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 82px;
  text-align: left;
}

.activeAnswerContainer {
  border-radius: 70px;
  border: 3px solid #57e7fc;
  background: linear-gradient(156deg, #c4f2f8 60.09%, #d4f7fc 85.49%);
  box-shadow: 8px 7px 10px 0px rgba(32, 143, 178, 0.29) inset,
    -3px -5px 9px 0px rgba(255, 255, 255, 0.67) inset,
    2px 6px 4px 0px rgba(58, 148, 154, 0.33) inset;
  width: 100%;
  padding: 6px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 82px;
  text-align: left;
}
.green {
  border-radius: 70px;
  border: 3px solid #0ccabf;
  background: var(--Soft-Turquoise, #e9ffff);
  box-shadow: 0px 4px 5px 0px rgba(255, 255, 255, 0.59) inset;
  width: 100%;
  padding: 6px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 82px;
  text-align: left;
}

.greenText {
  color: #0ccabf;
  font-family: IQOS Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.greenBubble {
  border-radius: 50%;
  background: #0ccabf;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red {
  border-radius: 70px;
  border: 3px solid #d43350;
  background: var(--White, #fff);
  box-shadow: 0px 4px 5px 0px rgba(255, 255, 255, 0.59) inset;
  width: 100%;
  padding: 6px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 82px;
  text-align: left;
}

.redText {
  color: #d43350;
  font-family: IQOS Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.redBubble {
  border-radius: 50%;
  background: #d43350;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  border-radius: 50%;
  background: #26d7ee;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubbleShine {
  position: absolute;
  top: 0;
}

.answerText {
  color: var(--Slate-Grey, #34303d);
  font-family: IQOS Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.button {
  border-radius: 50px;
  border: 1px solid var(--White, #fff);
  background: #e9ffff;
  padding: 10px;
  width: 100%;
}

.buttonText {
  border-radius: 100px;
  border: 1px solid rgba(152, 249, 255, 0);
  background: #26d7ee;
  box-shadow: 4px 7px 11px -6px #01366a, 6px 8px 8px -3px #73f2ff inset,
    -2px -4px 7px 0px rgba(0, 175, 186, 0.69) inset,
    -7px -8px 17px -5px rgba(102, 237, 255, 0.31);
  width: 100%;
  padding: 24px 0px;
  color: var(--Slate-Grey, #34303d);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

@media screen and (max-height: 600px) {
  .title {
    font-size: 20px;
  }
  .answerContainer {
    min-height: 52px;
    max-height: 52px;
  }

  .bubble {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }

  .redBubble {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
  .greenBubble {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }

  .bubbleShine {
    width: 40px;
    height: 40px;
  }

  .icon {
    width: 90%;
    height: 60%;
  }
  .buttonText {
    padding: 16px 0;
  }
}

@media screen and (max-height: 660px) {
  .title {
    font-size: 20px;
  }
}
