.final {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  background-image: url('../../images/final.png');
  background-size: cover;
  background-position: center;
}

.navbar {
  display: flex;
  gap: 1px;
  align-items: center;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  padding: 2px;
  width: 100%;
  margin-bottom: 32px;
}

.nav {
  border-radius: 20px;
  background: #fff;
  width: 100%;
  height: 6px;
}

.title {
  color: var(--White, #fff);
  font-family: IQOS Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 112.5% */
  margin-bottom: 32px;
}

.text {
  color: #fff;
  text-align: center;

  /* New Desktop_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  max-width: 220px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  border-radius: 50px;
  border: 1px solid var(--White, #fff);
  background: #e9ffff;
  padding: 10px;
  width: 100%;
}

.buttonText {
  border-radius: 100px;
  border: 1px solid rgba(152, 249, 255, 0);
  background: #26d7ee;
  box-shadow: 4px 7px 11px -6px #01366a, 6px 8px 8px -3px #73f2ff inset,
    -2px -4px 7px 0px rgba(0, 175, 186, 0.69) inset,
    -7px -8px 17px -5px rgba(102, 237, 255, 0.31);
  width: 100%;
  padding: 24px 0px;
  color: var(--Slate-Grey, #34303d);
  text-align: center;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
